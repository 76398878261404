import {updateDates, updateDatesFromEvents} from '../../commons/actions/dates'
import {INJECT_COMPONENT_DRAFT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {LOAD_ALL_EVENTS, LOAD_CALENDAR_EVENTS, LOAD_EVENTS} from '../actions/events'

export const datesMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case INJECT_COMPONENT_DRAFT:
    case UPDATE_COMPONENT_DRAFT.SUCCESS: {
      store.dispatch(updateDatesFromEvents({eventsList: action.payload.component.events}))
      break
    }
    case LOAD_ALL_EVENTS.SUCCESS:
    case LOAD_CALENDAR_EVENTS.SUCCESS:
    case LOAD_EVENTS.SUCCESS: {
      store.dispatch(updateDates(action.payload.dates))
      break
    }
    default:
      break
  }
  next(action)
}
